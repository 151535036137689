

$('.wt-burger').click(function(){
  $('#top_menu').toggleClass('wt-mobile-menu');
});


$('.wt-lenguage').click(function(){
  if ( $('.wt-dropdown').css('display') == 'none' )
  {
      $('.wt-item').removeClass('opened');
      $('.wt-dropdown').css('display','block');
      $('.wt-dropdown').css('opacity','1');
      $('.wt-lenguage').addClass('opened');
  }
  else
      {$('.wt-dropdown').css('opacity','0');
      $('.wt-lenguage').removeClass('opened');
      setTimeout(function(){
          $('.wt-dropdown').css('display','none');
      }, 200);
  }
});

function changeLang(lang){
window.location.href;
}

function menu (dropid) {
  $('.wt-dropdown').css('opacity','0');
  $('.wt-lenguage').removeClass('opened');
  setTimeout(function(){
      $('.wt-dropdown').css('display','none');
  }, 200)

  $(dropid).toggleClass('opened');
  $(".wt-item").not(dropid).removeClass("opened");
}


$(function($){
  $(document).mouseup(function (e){ // событие клика по веб-документу
      var div2 =  $(".wt-lenguage");
      var div = $(".wt-item"); // тут указываем ID элемента
      if ((!div.is(e.target)&& div.has(e.target).length === 0)&&(!div2.is(e.target)&& div2.has(e.target).length === 0)) { // и не по его дочерним элементам
          $(".wt-item").removeClass("opened");
          $('.wt-dropdown').css('opacity','0');
          $('.wt-lenguage').removeClass('opened');
          setTimeout(function(){
              $('.wt-dropdown').css('display','none');
          }, 200);
      }
  });
});


$('.wt-item-menu').click(function() {
  $(this).toggleClass('opened');
});
///////////////mobile filters
$('.filters-mobile-open').click("click", function() {
  $('.wt-mobile-filters').toggle();
  setTimeout(function(){
      $('.wt-mobile-filters').toggleClass('wt-opened-mobile-filters')
  }, 0);
  
});
$('#filters_mobile_close').on("click", function() {
  $('.wt-mobile-filters').toggleClass('wt-opened-mobile-filters')
  setTimeout(function(){
      $('.wt-mobile-filters').toggle();
  }, 200);
});

$('.wt-item-filters').click(function() {
  $(this).toggleClass('wt-ul-opened');
});

///////////////card slider
$('.wt-item-slider').slick({
lazyLoad: 'ondemand',
// infinite: true,
// swipeToSlide: false,
// slidesToShow: 1,
// slidesToScroll: 1,
arrows: true,
fade: true,
// asNavFor: '.wt-slider-dots'
});
$('.wt-slider-dots').slick({
lazyLoad: 'ondemand',
// infinite: true,
swipeToSlide: false,
slidesToShow: 3,
slidesToScroll: 1,
asNavFor: '.wt-item-slider',
centerMode: false,
focusOnSelect: true
});

///////////////tabs
$('.wt-tab-button').on("click", function() {
  $(".wt-tab-button").removeClass("wt-selected");
  $(this).addClass('wt-selected');

  var attr;
  attr = $(this).attr('href')
  $('.tab-content').removeClass('wt-selected');
  $('.'+attr).addClass('wt-selected');
});

///////////////// main-slider
function detect_active(){
  var get_active = $("#dp-slider .dp_item:first-child").data("class");
  $("#dp-dots li").removeClass("active");
  $("#dp-dots li[data-class="+ get_active +"]").addClass("active");
}
$("#dp-next").click(function(){
  var total = $(".dp_item").length;
  $("#dp-slider .dp_item:first-child").hide().appendTo("#dp-slider").fadeIn();
  $.each($('.dp_item'), function (index, dp_item) {
    $(dp_item).attr('data-position', index + 1);
  });
  detect_active();

});

$("#dp-prev").click(function(){
  var total = $(".dp_item").length;
  $("#dp-slider .dp_item:last-child").hide().prependTo("#dp-slider").fadeIn();
  $.each($('.dp_item'), function (index, dp_item) {
    $(dp_item).attr('data-position', index + 1);
  });

  detect_active();
});

$("#dp-dots li").click(function(){
  $("#dp-dots li").removeClass("active");
  $(this).addClass("active");
  var get_slide = $(this).attr('data-class');
  $("#dp-slider .dp_item[data-class=" + get_slide + "]").hide().prependTo("#dp-slider").fadeIn();
  $.each($('.dp_item'), function (index, dp_item) {
    $(dp_item).attr('data-position', index + 1);
  });
});


$("body").on("click", "#dp-slider .dp_item:not(:first-child)", function(){
  var get_slide = $(this).attr('data-class');
  $("#dp-slider .dp_item[data-class=" + get_slide + "]").hide().prependTo("#dp-slider").fadeIn();
  $.each($('.dp_item'), function (index, dp_item) {
    $(dp_item).attr('data-position', index + 1);
  });

  detect_active();
});


////////////accordion

function toggleFunc(e){

  let li = document.querySelectorAll('li');

          for (var i = 0; i < li.length; i++) {
            li[i].classList.remove('active');
          }
              e.classList.add('active');
};

$("#li1").on('click', function() {
$(".wt-img-block-ac").css('background-image', "url('/libs/img/slider3.png')");
});

$("#li2").on('click', function() {
$(".wt-img-block-ac").css('background-image', "url('/libs/img/slider2.png')");
});

$("#li3").on('click', function() {
$(".wt-img-block-ac").css('background-image', "url('/libs/img/slider1.png')");
});

////////// footer contact form

document.getElementById('wtFooterContactFormInput').addEventListener('input', function(e){
var t = e.target.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/);
e.target.value = "+" + (t[2] ? t[1] + "-" + t[2] + (t[3] ? "-" + t[3] + (t[4] ? "-" + t[4] : "") : "") : t[1]);
})

function footerContactForm(){

let phone = document.getElementById('wtFooterContactFormInput').value;
phone = String(phone);



let formData = new FormData();
formData.append('phoneNumber', phone);

$.ajax({
  url: "/footer_contact_sand",
  type: "post",
  contentType: false,
  processData: false,
  cache: false,
  data: formData,
  success: function (response) {
    response = JSON.parse(response);
      if(response['status']){
        $('#wtFooterContactFormInput').css('background-color', '#d2e6d1').val('').attr("placeholder", response['true']);
        heap.identify(phone);
        heap.addUserProperties({
          'Account Name': '',
          'Account Email': '',
          'Account Phone': phone
        });
        heap.track('BuyByPhone');
      }
      else {
        $('#wtFooterContactFormInput').css('background-color', '#ff796f').val('').attr("placeholder", response['false']);
      }
    },
  error: function(jqXHR, textStatus, errorThrown) {
      
  }
});
}

////////// slider/tab service

$('.wt-information-service-container').slick({
lazyLoad: 'ondemand',

responsive: [
  {
    breakpoint: 9999,
    settings: "unslick"
  },
  {
    breakpoint: 767,
    settings: {
      arrows:false,
      infinite:true,
      slidesToShow: 1,
      slidesToScroll:1,
      fade:true,
      adaptiveHeight: true
    }
  }
]
});
$('.wt-services-tabs-buttons').slick({
lazyLoad: 'ondemand',

asNavFor:".wt-information-service-container",

  responsive: [
    {
        breakpoint: 9999,
        settings: "unslick"
    },
    {
      breakpoint: 767,
      settings: {
      infinite:true,
      arrows:true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]

});

$('.wt-service-tab ').on("click", function() {
$(".wt-service-tab").removeClass("wt-selected-tab");
$(this).addClass('wt-selected-tab');
var atr;
atr = $(this).attr('href')

$('.wt-services-tab-content').removeClass('wt-selected-tab');
$('.'+atr).addClass('wt-selected-tab');

});
////////// slider price  

$('.wt-price-serv-block').slick({
lazyLoad: 'ondemand',
infinite: true,
swipeToSlide: true,
slidesToShow: 1,
slidesToScroll: 1,
arrows: true,
fade: false,
variableWidth: true,
centerMode: true,
responsive: [
  {
    breakpoint: 9999,
    settings: "unslick"
  },
  {
    breakpoint: 767,
    settings: {
    slidesToShow: 2,
    slidesToScroll: 1,
    }
  },
  {
    breakpoint: 520,
    settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    }
  },
  {
    breakpoint: 400,
    settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    }
  }
]
});

/////////////map
var map;

function initialize() {
// Create a simple map.
map = new google.maps.Map(document.getElementById('map'), {
  zoom: 16,
  mapId: "7af374cea034b8b5",
  mapTypeControl: !1,
  fullscreenControl: !1,
  disableDefaultUI: !0,
  scrollwheel: !1,
  draggable: 0,
  zoomControl: !1,
  disableDoubleClickZoom: !0,
  draggableCursor: "default",
  center: { lat: 51.2175369, lng: 27.6651079 }
});
const image = "libs/img/map-marker.png";
new google.maps.Marker({

  position: { lat: 51.21780000624493, lng: 27.666730058195895 },
  map,
  icon: image,
  title: "WOOD TRADE ltd",
});



// Load a GeoJSON from the same server as our demo.
//map.data.loadGeoJson('http://pixelsandcode.local:5757/map.json');
}
google.maps.event.addDomListener(window, 'load', initialize);


///////////// Certificate
$('.wt-certificate-slider .wt-close-slider').on("click", function() {
$('.wt-certificate-slider').toggleClass('wt-certificate-slider-visible');
});

$('.wt-container-certificate .certificate img').on("click", function() {
$('.wt-certificate-slider').toggleClass('wt-certificate-slider-visible');
});

$('.wt-certificate-slider .wt-slider-wrap .wt-slider').slick({
slidesToShow: 1,
slidesToScroll: 1,
infinite: true,
centerMode: true,
arrows: true,
fade: true
});


///////////// Buy form
function openCloseBuyForm() {
let name = $('.wt-buy-form .input-name').val('');
let tel = $('.wt-buy-form .input-tel').val('');
let email = $('.wt-buy-form .input-email').val('');

if($('.wt-buy-form .wt-message').css('display') == 'block') {
  $('.wt-buy-form .wt-message').slideToggle(400);
}

$('.wt-buy-form-section').fadeToggle();
}

// document.body.contains(
let buyFormTelInput = document.getElementById('buyFormTelInput');
if(document.body.contains(buyFormTelInput)) {
buyFormTelInput.addEventListener('input', function(e){
  var t = e.target.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/);
  e.target.value = "+" + (t[2] ? t[1] + "-" + t[2] + (t[3] ? "-" + t[3] + (t[4] ? "-" + t[4] : "") : "") : t[1]);
})
}



function buyFormSubmit(){
let message = $('.wt-buy-form .wt-message');

if(message.css('display') == 'block') {
  $('.wt-buy-form .wt-message').slideToggle(70);
}

// let data = [];

let name = $('.wt-buy-form .input-name').val();
let tel = $('.wt-buy-form .input-tel').val();
let email = $('.wt-buy-form .input-email').val();

let formData = new FormData();
formData.append('name', name);
formData.append('phone', tel);
formData.append('email', email);

$.ajax({
  url: "/submit-form",
  type: "post",
  contentType: false,
  processData: false,
  cache: false,
  data: formData,
  success: function (response) {
    response = JSON.parse(response);

    if(response['status']){
      $('.wt-buy-form .wt-message').html(response['true']).css('background-color', '#d2e6d1').slideToggle(400);
      heap.identify(email);
      heap.addUserProperties({
        'Account Name': name,
        'Account Email': email,
        'Account Phone': tel
      });
      heap.track('BuyByEmail');
    }
    else {
      $('.wt-buy-form .wt-message').css('background-color', '#ff796f').slideToggle(400).html(response['false']);
    }
  },
  error: function(jqXHR, textStatus, errorThrown) {
      
  }
});
}